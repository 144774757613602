import { Link } from 'gatsby';
import { darken } from 'polished';
import { setLightness } from 'polished';
import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { colors } from '../styles/colors';
import { outer, inner } from '../styles/shared';
import config from '../website-config';

import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import ReactMarkdown from 'react-markdown'
import CookieBanner from 'react-cookie-banner';
import Obfuscate from 'react-obfuscate';

interface FooterProps {
  strapiFooter: {
    contacts: string
    sponsors: Array<{
      localFile: IGatsbyImageData
    }>;
  };
}

export const Footer: React.FC = () => {
  
  const data: FooterProps = useStaticQuery(graphql`
    query footerPageQuery {

      strapiFooter {
        contacts
        sponsors {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)

  return (
    <>
    <footer css={[outer, SiteFooter]}>
      <div css={[inner, SiteFooterContent]}>
        <section className="contacts">
            <ReactMarkdown
              children={data.strapiFooter.contacts}
              components={{
                link: ({href}) => <span><Obfuscate email={href?.substring(7)}/></span>
              }}
            />
            
        </section>

        <SiteFooterNav>
          {/* <Link to="/">Latest Posts</Link> */}
          {config.facebook && (
            <a href={config.facebook} target="_blank" rel="noopener noreferrer">
              Facebook
            </a>
          )}
          {config.instagram && (
            <a href={config.instagram} target="_blank" rel="noopener noreferrer">
              Instagram
            </a>
          )}
          
          <Link to="/cookieLaw">Cookie Law</Link>
          <Link to="/privacy">Privacy</Link>
          <Link to="/legge-124">Legge 124/2017</Link>
        </SiteFooterNav>

        <section className="thanksTo" style={{margin: '10px auto', width: '80%'}}>          
            {/* Si ringrazia: */}
            {
              data.strapiFooter.sponsors.map(sponsor => (
                <GatsbyImage
                  style={{width: `${(100/data.strapiFooter.sponsors.length) - 2}%`}}
                  image={getImage(sponsor.localFile)!} alt="si ringrazia"
                  objectFit='contain'
                />
              ))
            }
        </section>

        <section className="copyright" style={{fontSize: "1.2rem"}}>
          <Link to="/">{config.title} A.P.S.</Link> &copy; {new Date().getFullYear()}{' '}
          | web design by  <a href="https://www.dsharp.it" target="_blank" rel="noopener noreferrer">Matteo Gervasi</a>{' '}
          | theme based on  <a href="https://github.com/scttcper/gatsby-casper" target="_blank" rel="noopener noreferrer">Gatsby Casper</a>
          {/* {config.footer && (
            <Link to="/">
              | {config.title} {config.footer}
            </Link>
          )} */}
        </section>
      
      </div>
    </footer>
    <CookieBanner
        styles={{
          
          banner: {height: 'auto', position: 'fixed', bottom: 0, backgroundColor: `${colors.bandaYellow}`},
          message: {
            display: 'block',
            padding: '12px 90px 12px 12px',
            lineHeight: 1.3,
            textAlign: 'left',
            fontSize: 14,
            fontWeight: 200,
            color: 'black'
          },
          button: {
            backgroundColor: `${colors.bandaBlue}`,
            color: 'white'
          },
          link: {
            fontWeight: 400,
            color: 'black'
          }
          //message: { fontWeight: 400 }
        }}
        message="Questo sito utilizza cookie di profilazione di terze parti. Accetta l’uso dei cookie per continuare la navigazione."
        link={<Link to="/cookieLaw">Maggiori informazioni sull'uso dei cookie</Link>}
        buttonMessage='Ok'
        dismissOnScroll={false}
        dismissOnClick={false}
      />
    </>

  );
};

const SiteFooter = css`
  position: relative;
  padding-top: 20px;
  padding-bottom: 60px;
  color: #fff;
  /*background: ${setLightness('0.0015', colors.darkgrey)};*/
  background: ${darken('0.05', colors.bandaBlue)};
`;

const SiteFooterContent = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.5rem;
  a {
    color: rgba(255, 255, 255, 0.8);
  }
  a:hover {
    color: ${colors.bandaYellow}; //rgba(255, 255, 255, 1);
    text-decoration: none;
  }
  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

const SiteFooterNav = styled.nav`
  display: flex;

  a {
    position: relative;
    margin-left: 20px;
  }

  a:before {
    content: '';
    position: absolute;
    top: 11px;
    left: -11px;
    display: block;
    width: 2px;
    height: 2px;
    background: #fff;
    border-radius: 100%;
  }

  a:first-of-type:before {
    display: none;
  }
  @media (max-width: 650px) {
    a:first-of-type {
      margin-left: 0;
    }
  }
`;

export default Footer;